<script setup lang="ts">
import { getNodeLabel } from '@ory/integrations/ui'

definePageMeta({
  layout: 'auth'
})

useSeoMeta({
  title: 'Login'
})

const { flow, fields, isLoading, messages, goToRoute } = useCreateFlowForForm('login')

</script>
<template>
  <CardAuth title="Iniciar sesión" description="Ingresa tus credenciales para acceder a tu cuenta">
    <Alerts v-if="messages?.length" :messages="messages" class="mt-5" />
    <div v-if="!isLoading && flow && fields?.length">
      <form :action="flow.ui.action" :method="flow.ui.method">
        <div v-for="(field, index) in fields" :key="index">
          <div v-if="field.attributes.type !== 'submit'" class="py-3 -mt-2">
            <label v-if="field.attributes.type !== 'submit'" :for="field.attributes.name" class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">{{ getNodeLabel(field) }}</label>
            <UInput
              v-if="field.attributes.type !== 'submit'"
              :id="field.attributes.name"
              :name="field.attributes.name"
              :type="field.attributes.type"
              :autocomplete="field.attributes.name"
              :required="field.attributes.required"
              :value="field.attributes.value"
              :placeholder="formatLabel(field?.attributes?.name)"
            />
          </div>
          <UButton
            v-else
            :id="field.attributes.name"
            :name="field.attributes.name"
            type="submit"
            :autocomplete="field.attributes.name"
            :required="field.attributes.required"
            :value="field.attributes.value"
            block
            class="mt-4"
          >
            Entrar
          </UButton>
        </div>
      </form>
    </div>
    <div v-else>
      <SkeletonLogin />
    </div>
    <div class="flex items-center justify-end mt-5">
      <div class="text-sm">
        <span class="font-medium text-primary hover:text-primary/90 cursor-pointer dark:text-secondary dark:hover:text-secondary-600" @click="goToRoute('/auth/recovery')">
          ¿Olvidaste tu contraseña?
        </span>
      </div>
    </div>
  </CardAuth>
</template>